.dark {
  --color-bg-primary: #36393f;
  --color-bg-secondary: #2f3136;
  --color-bg-tertiary: #202225;
  --color-bg-quaternary: #42464d;
  --color-bg-quinary: #18191c;

  --color-text-primary: #ffffff;
  --color-text-secondary: #b9bbbe;

  --color-bg-interactive: #0071bc;
  --color-bg-error: #bf0000;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #f2f3f5;
  --color-bg-tertiary: #e3e5e8;
  --color-bg-quaternary: #d3d7da;
  --color-bg-quinary: #ffffff;

  --color-text-primary: #060607;
  --color-text-secondary: #4f5660;

  --color-bg-interactive: #0071bc;
  --color-bg-error: #bf0000;
}
