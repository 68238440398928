@import "tailwind.scss";
@import "colors.scss";

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Saira", sans-serif;
  transition: 0.1s;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
  box-sizing: border-box;
}
body {
  font-family: "Lato", sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 1.2;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
